const baseURI = `https://us-central1-covidb-a3df4.cloudfunctions.net`;

export async function pullAllDoc(callback) {
  let response = await fetch(`${baseURI}/getAll`);
  response = await response.json();
  //response.map(callback);
  callback(response);
}

export async function pullNonTechDoc(callback) {
  let response = await fetch(`${baseURI}/getNonTechnical`);
  response = await response.json();
  //response.map(callback);
  callback(response);
}

export async function pullTechnicalDoc(callback) {
  let response = await fetch(`${baseURI}/getTechnical`);
  response = await response.json();
  //response.map(callback);
  callback(response);
}

export async function pullQueryDoc(callback, tags) {
  let response = await fetch(
    `${baseURI}/queryKeywords?keywords=${tags.join(",")}`
  );
  response = await response.json();
  //response.map(callback);
  callback(response);
}
