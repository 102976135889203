export default [
    {
        title: "Covid Tips for Everyday Situations in India",
        desc: `In Episode 5 of the CoviDB Speaker Series, social entrepreneur and Indian film actress Amala Akkineni is joined by Dr. S. V. Mahadevan, Professor of Emergency Medicine at Stanford University School of Medicine, as they discuss current practical advice for residents of India on COVID-19 prevention. 
 
        With the world’s second largest population, India has been deeply affected by the COVID-19 pandemic. As Indians continue to fight against this virus while also attempting to navigate everyday life, there are many situations in which the risk of Covid and the recommended precautions may not be not clear-cut. From going to college and university campuses to meeting with friends in homes and receiving package deliveries, there are important guiding principles to navigate these situations in a safer manner.`,
        thumb: "/videolist/video5.png", 
        videoList: [
          { 
            url: "https://www.youtube.com/embed/w1BiXinSANg",
            topic: "Introduction",
            video_id: "w1BiXinSANg",
          },
          { 
            url: "https://www.youtube.com/embed/Q2fMfZnbfyc",
            topic: "Staying safe at school",
            video_id: "Q2fMfZnbfyc",
          },
          { 
            url: "https://www.youtube.com/embed/itqqSvyTpLw",
            topic: "Staying safe at weddings",
            video_id: "itqqSvyTpLw",
          },
          { 
            url: "https://www.youtube.com/embed/Eh02tt9u30w",
            topic: "Staying safe while hosting guests",
            video_id: "Eh02tt9u30w",
          },
          { 
            url: "https://www.youtube.com/embed/fg83gcZpw4U",
            topic: "Handling packages & food",
            video_id: "fg83gcZpw4U",
          },
          { 
            url: "https://www.youtube.com/embed/Mjg1L0AttwQ",
            topic: "When to go to a hospital",
            video_id: "Mjg1L0AttwQ",
          },
          { 
            url: "https://www.youtube.com/embed/vBrYXbuZOJs",
            topic: "Vaccines",
            video_id: "vBrYXbuZOJs",
          },
          { 
            url: "https://www.youtube.com/embed/4LD6-jTOjSY",
            topic: "Hope for the future",
            video_id: "4LD6-jTOjSY",
          },
          { 
            url: "https://www.youtube.com/embed/JSwPucvxE3Y",
            topic: "Concluding thoughts",
            video_id: "JSwPucvxE3Y",
          }
        ],
        short: "In Episode 5 of the CoviDB Speaker Series, social entrepreneur and Indian film actress Amala Akkineni is joined by Dr. S. V. Mahadevan, Professor of Emergency Medicine at Stanford University School of Medicine, as they discuss current practical advice for residents of India on COVID-19 prevention.",
        teachersGuide: "https://teachaids.org/covidb/studyguides/covidb-studyguide-ep05.pdf",
    },
    {
        title: "The Olympics & COVID-19",
        desc: `The sports world has been dramatically affected by COVID-19. Not only has there been an absence of events for the spectator in person and on television, but the impact on the participants themselves has been unprecedented. Due to social gathering restrictions, organized youth sports have been almost completely shuttered. High schools and colleges have been cancelling their practices and competitive seasons. This has also had a dramatic effect on sport at the highest level. Only recently have there been abbreviated attempts to reinstitute professional sports seasons such as Major League Baseball, the NBA and the NFL. Even the 2020 Olympic Games in Tokyo were postponed to 2021. 
        In Episode 4 of the CoviDB Speaker Series, we look at the impact this pandemic has had on the preparation and life of two of the world’s greatest athletes. We are fortunate to have the expertise of the versatile and renowned sportscaster Ted Robinson as he interviews three-time Olympic Diver and Gold Medalist Laura Wilkinson and five-time Olympic Gold Medalist Swimmer Katie Ledecky.`,
        thumb: "/videolist/video4.png",
        videoList: [
          {
            url: "https://www.youtube.com/embed/tHnrOcmMGzk",
            topic: "Introduction",
            video_id: "tHnrOcmMGzk",
          },
          {
            url: "https://www.youtube.com/embed/TN3Y3N5EkZI",
            topic: "Changes to Olympic training plans",
            video_id: "TN3Y3N5EkZI",
          },
          {
            url: "https://www.youtube.com/embed/DLwNYmPO_Xc",
            topic: "Training during COVID-19",
            video_id: "DLwNYmPO_Xc",
          },
          {
            url: "https://www.youtube.com/embed/4NnmWizubjE",
            topic: "Caution and safety during COVID-19",
            video_id: "4NnmWizubjE",
          },
          {
            url: "https://www.youtube.com/embed/McU66iD0Y38",
            topic: "Mental health during COVID-19",
            video_id: "McU66iD0Y38",
          },
          {
            url: "https://www.youtube.com/embed/cuyN6-Tuvd0",
            topic: "Illness & perspective on COVID-19",
            video_id: "cuyN6-Tuvd0",
          },
          {
            url: "https://www.youtube.com/embed/CRk0BtDvZco",
            topic: "Return to “normal”?",
            video_id: "CRk0BtDvZco",
          },
          {
            url: "https://www.youtube.com/embed/lFbEO1E8YAk",
            topic: "Attitude toward the sport",
            video_id: "lFbEO1E8YAk",
          },
          {
            url: "https://www.youtube.com/embed/g8aFhgEIeeE",
            topic: "Advice for young athletes",
            video_id: "g8aFhgEIeeE",
          },
          {
            url: "https://www.youtube.com/embed/L6r4tHR0fPI",
            topic: "The Olympic moment",
            video_id: "L6r4tHR0fPI",
          },
          {
            url: "https://www.youtube.com/embed/hTt2_C2tl2M",
            topic: "Concluding thoughts",
            video_id: "hTt2_C2tl2M",
          }
        ],
        short: "In Episode 4 of the CoviDB Speaker Series, we look at the impact this pandemic has had on the preparation and life of two of the world’s greatest athletes. We are fortunate to have the expertise of the versatile and renowned sportscaster Ted Robinson as he interviews three-time Olympic Diver and Gold Medalist Laura Wilkinson and five-time Olympic Gold Medalist Swimmer Katie Ledecky.",
        teachersGuide: "https://teachaids.org/covidb/studyguides/covidb-studyguide-ep04.pdf",
    },
    {
        title: "Violence Against Women During COVID-19",
        desc: `Following the global outbreak of COVID-19, rates of violence against women have risen dramatically. Such factors as increased substance use, widespread financial stress, and general anxiety have contributed to this dangerous increase in violence.
        At the same time, women have less contact with personal support networks and less access to legal, social, and health service systems that may provide lifesaving resources for them. The result of these compounding factors is what experts have described as the “invisible pandemic” of violence against women during the COVID-19 crisis.
        
        In Episode 3 of the CoviDB Speaker Series, we discuss the complexities of these urgent issues with the Founding President of the Global Fund for Women, Anne Firth Murray, a leading voice in the fields of women’s health and human rights.`,
        thumb: "/videolist/video3.png",
        videoList: [
          {
            url: "https://www.youtube.com/embed/-HJFdEio4-Y",
            topic: "Introduction",
            video_id: "-HJFdEio4-Y",
          },
          {
            url: "https://www.youtube.com/embed/_Cer1aNiaQ0",
            topic: "What is violence against women?",
            video_id: "_Cer1aNiaQ0",
          },
          {
            url: "https://www.youtube.com/embed/wbCjrn5bzP4",
            topic: "Causes of violence against women",
            video_id: "wbCjrn5bzP4",
          },
          {
            url: "https://www.youtube.com/embed/VSfrWjKThOE",
            topic: "Measuring violence against women",
            video_id: "VSfrWjKThOE",
          },
          {
            url: "https://www.youtube.com/embed/JMKXrxiYS4k",
            topic: "COVID & violence Against Women",
            video_id: "JMKXrxiYS4k",
          },
          {
            url: "https://www.youtube.com/embed/jEiZucTK8K0",
            topic: "Responses to COVID & violence against women",
            video_id: "jEiZucTK8K0",
          },
          {
            url: "https://www.youtube.com/embed/RRmxhsYGSNc",
            topic: "How you can help",
            video_id: "RRmxhsYGSNc",
          },
          {
            url: "https://www.youtube.com/embed/PN9yp9sWozI",
            topic: "Concluding thoughts",
            video_id: "PN9yp9sWozI",
          }
        ],
        teachersGuide: "https://teachaids.org/covidb/studyguides/covidb-studyguide-ep03.pdf",
        short: "Violence Against Women During the COVID-19 Crisis; Anne Firth Murray, Founding President of the Global Fund for Women, in conversation with Dr. Piya Sorcar, Founder & CEO of TeachAids."
    },
    {
        title: "Cybersecurity & Privacy in the Era of COVID-19",
        desc: `Bernadette Clavier, Director of the Center for Social Innovation at the Stanford Graduate School of Business, interviews Shuman Ghosemajumder, Global Head of Artificial Intelligence at F5, and former Head of Product for Trust & Safety at Google, about privacy, cybersecurity, and technology issues relating to coronavirus, contact tracing, sheltering-in-place, and remote working.`,
        thumb: "/videolist/video2.png",
        videoList: [
          {
            url: "https://www.youtube.com/embed/2Jz_pUMVvTo",
            topic: "Introduction",
            video_id: "2Jz_pUMVvTo",
          },
          {
            url: "https://www.youtube.com/embed/f95C0jeHdEw",
            topic: "Technology in a pandemic",
            video_id: "f95C0jeHdEw",
          },
          {
            url: "https://www.youtube.com/embed/HIkghH9YCck",
            topic: "Contact tracing & privacy",
            video_id: "HIkghH9YCck",
          },
          {
            url: "https://www.youtube.com/embed/KmBmIuyf5Uo",
            topic: "Efficacy of contact tracing",
            video_id: "KmBmIuyf5Uo",
          },
          {
            url: "https://www.youtube.com/embed/icZTL9anPEc",
            topic: "Risks of sharing data for contact tracing",
            video_id: "icZTL9anPEc",
          },
          {
            url: "https://www.youtube.com/embed/knEf_uw6w2I",
            topic: "Contact tracing in the US",
            video_id: "knEf_uw6w2I",
          },
          {
            url: "https://www.youtube.com/embed/LZkmyHn_3uo",
            topic: "Cyber security",
            video_id: "LZkmyHn_3uo",
          },
          {
            url: "https://www.youtube.com/embed/ob7YlOVZaGU",
            topic: "Key takeaways",
            video_id: "ob7YlOVZaGU",
          },
          {
            url: "https://www.youtube.com/embed/A7v1GB-t8yE",
            topic: "Concluding thoughts",
            video_id: "A7v1GB-t8yE",
          }
        ],
        teachersGuide: "https://teachaids.org/covidb/studyguides/covidb-studyguide-ep02.pdf"
    },
    {
        title: "COVID-19 Facts and Myths",
        desc: `The first episode of the CoviDB speaker series is an interview with Dr. Anurag Mairal, Adjunct Professor of Medicine and Director, Global Outreach Programs at the Stanford Byers Center for Biodesign. With his background and expertise in health innovation and biodesign, Dr. Mairal gives an overview of COVID-19 and clears up some common misconceptions with the latest developments. He is interviewed by Dr. Piya Sorcar, CEO & Founder of TeachAids, Lecturer at Stanford University, and Faculty Fellow, Center for Global Health, Stanford School of Medicine.`,
        thumb: "/videolist/video1.png",
        videoList: [
          {
            url: "https://www.youtube.com/embed/kNTUyTONdjs",
            topic: "Introduction",
            video_id: "kNTUyTONdjs",
          },
          {
            url: "https://www.youtube.com/embed/lWLHwRnSoMY",
            topic: "Why is COVID-19 different?",
            video_id: "lWLHwRnSoMY",
          },
          {
            url: "https://www.youtube.com/embed/fDn3jrKGyBo",
            topic: "COVID-19 testing",
            video_id: "fDn3jrKGyBo",
          },
          {
            url: "https://www.youtube.com/embed/BUIFCJ5OJEM",
            topic: "COVID-19 common misconceptions",
            video_id: "BUIFCJ5OJEM",
          },
          {
            url: "https://www.youtube.com/embed/xUpJk4g0UK0",
            topic: "Predictions for the future",
            video_id: "xUpJk4g0UK0",
          },
          {
            url: "https://www.youtube.com/embed/15pH_zzQizg",
            topic: "Insights about the pandemic",
            video_id: "15pH_zzQizg",
          },
          {
            url: "https://www.youtube.com/embed/7O41cCpxsvA",
            topic: "Concluding thoughts",
            video_id: "7O41cCpxsvA",
          }
        ],
        teachersGuide: "https://teachaids.org/covidb/studyguides/covidb-studyguide-ep01.pdf"
    },
]
