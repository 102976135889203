import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import SectionCards from "./NewsPage/SectionCards";
import Video from "./VideoPage/Video"
import * as db from '../firebase'
import loading_image from "./assets/loading_image.png"

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{paddingLeft: 0, paddingRight: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Home extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      doc: [],
      technical: false,
      query: "",
      querySplit: []
    }

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleTechnical = this.handleTechnical.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    // this.shuffle = this.shuffle.bind(this);
  }

  handleTechnical() {
    this.setState({technical: !this.state.technical}, this.pullData(!this.state.technical))  
  }

  handleTabChange(event, newValue) {
    if (newValue !== 2) {
      this.setState({value: newValue, query: ""});
      this.pullData();
    }
    if (newValue === 1) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({pageID: 'video'}, 'Video', '/Video');
    } else if (newValue === 0) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({pageID: 'home'}, 'Home', '/');
    }
  }

  pullData(params) {
    this.setState({doc: []})
    if(!Array.isArray(params) && params === true) {
      db.pullTechnicalDoc((val) => {
        /*
        let x = val
        x.id = val.id
        let array = this.state.doc
        array.splice(Math.floor(this.state.doc.length > 5 ? this.state.doc.length - (5 * Math.random()) : this.state.doc.length), 0, x)
        this.setState({doc: array})
        */
        if (val.length === 0) {
            val = ["No results found"]
        }
        this.setState({doc: val})
      })
    } else if (Array.isArray(params)) {
      db.pullQueryDoc((val) => {
        /*
        let x = val
        if (typeof val !== 'string') {
          x = val
          x.id = val.id
        }
        this.setState({doc: [...this.state.doc, x]})
        */
        if (val.length === 0) {
            val = ["No results found"]
        }
        this.setState({doc: val})
      }, params)
    } else {
      db.pullNonTechDoc((val) => {
        /*
        let x = val
        x.id = val.id
        let array = this.state.doc
        array.splice(Math.floor(this.state.doc.length > 5 ? this.state.doc.length - (5 * Math.random()) : this.state.doc.length), 0, x)
        this.setState({doc: array})
        
        this.setState({doc: [...this.state.doc, x]})
        */
        if (val.length === 0) {
            val = ["No results found"]
        }
        this.setState({doc: val})
      })
    }
  }

  searchSubmit(e) {
    if (e.key === "Enter") {
      if (this.state.query !== "") {
        let keywords = []
        let query = this.state.query.split(' ')
        query.forEach((keyword) => {
          let val = keyword.toLocaleLowerCase().replace(/(-|\.)+/, '')
          keywords.push(val)
        })
        this.setState({querySplit: keywords}, this.pullData(keywords))
      } else {
        this.pullData()
      }
    }
  }

  // shuffle(array) {
  //   let currentIndex = array.length,  randomIndex;
  
  //   // While there remain elements to shuffle...
  //   while (currentIndex != 0) {
  
  //     // Pick a remaining element...
  //     randomIndex = Math.floor(Math.random() * currentIndex);
  //     currentIndex--;
  
  //     // And swap it with the current element.
  //     [array[currentIndex], array[randomIndex]] = [
  //       array[randomIndex], array[currentIndex]];
  //   }
  
  //   return array;
  // }

  componentDidMount() {
    this.pullData()
  }

  a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }

  renderTop() {
    const { classes } = this.props;
    return (
      <div className={classes.heroContent}>
        <Container maxWidth="lg">
          <Typography>
            CoviDB is a community-built, expert-curated platform, organizing resources relating to the COVID-19 (coronavirus) pandemic.
            The site is operated by TeachAids in partnership with the Stanford Center for Innovation in Global Health, the Stanford Program
            on International and Cross-Cultural Education, FSI, and the UCSF, Institute for Global Health Sciences.
          </Typography>
        </Container>
      </div>);
  }

  renderBottom() {
    const { classes } = this.props;
    return (
      <div className={classes.blockContent}>
        <Container maxWidth="lg">
          <AppBar className={classes.appBar} elevation={0}>
            <Tabs variant="scrollable" scrollButtons={"auto"} TabIndicatorProps={{style: {backgroundColor: "#3a2193", height: "5px", willChange: "left, transition"}}} value={this.state.value} onChange={this.handleTabChange}>
              <Tab classes={{root: classes.appBarTab, selected: classes.appBarTabSelected}} label="News Feed" {...this.a11yProps(0)}/>
              <Tab classes={{root: classes.appBarTab, selected: classes.appBarTabSelected}} label="Speaker Series" {...this.a11yProps(1)}/>
              <Tab href="https://kids.covidb.org/" classes={{root: classes.appBarTab, selected: classes.appBarTabSelected}} label="COVIDB4Kids" target="_blank" {...this.a11yProps(2)} />
            </Tabs>
          </AppBar>
          <hr className={classes.sectionBreak}/>
            <TabPanel value={this.state.value} index={0}> 
                <div className={classes.searchSection}>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                      }}
                      inputProps={{ "aria-label": "search" }}
                      value={this.state.query}
                      onChange={event => this.setState({ query: event.target.value })}
                      onKeyPress={this.searchSubmit}
                    />       
                  </div>                                      
                    {/*<Grid container className={classes.grid}>
                      <Typography className={classes.technicalLabel}>Peer Reviewed Only</Typography>
                      <Checkbox style={{display: 'inline-block'}} color="primary" onChange={this.handleTechnical}/>
                    </Grid>*/}
                </div>
              {this.renderBody()}
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <Video/>
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
              <div style={{marginLeft: '50%'}}><CircularProgress/></div>
            </TabPanel>
        </Container>
      </div>);
  }

  renderBody() {
    // if the links have loaded from firebase render, else placeholder
    if (this.state.doc.length > 0 && this.state.doc[0] !== "No results found") {
      return (<div><SectionCards links={this.state.doc} /></div>);
    } else if (this.state.doc[0] === "No results found"){
      return (<div style={{marginLeft: '10%'}}><h2>No results found</h2></div>)
    } else {
      return (<div><img alt="loading" className={this.loadingImage} style={{width:"1200px"}} src={loading_image}/></div>);
    }
  }


  render() {
    return (
      <div>
        {this.renderTop()}
        {this.renderBottom()}
      </div>
    );
  }
}

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 0)
  },
  blockContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  whatIsCovIDPaper: {
    padding: theme.spacing(2)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  roundedRect: {
    borderRadius: 15
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  ctaButton: {
    //display: 'none',
    textTransform: "none",
    backgroundColor: "lightgray",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  ctaButtonText: {
    margin: 0
  },
  headerRowGridContainer: {
    display: "flex",
    alignItems: "stretch"
  },
  headerRowGridPaper: {
    width: "100%",
    height: "100%"
  },
  flexGrowAndJustifyCenter: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonLink: {
    color: "#000000",
    textDecoration: "none"
  },
  concludingContainer: {
    textAlign: "center"
  },
  clickButton: {
    width: "150px",
    borderRadius: 16,
    marginTop: 10,
    marginBottom: 10,
    height: "30px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "12px",
    color: "white", //hot pink
    backgroundColor: "#BE2E3B",
    "&:hover": {
      backgroundColor: "#A2A2A2"
    }
  },
  appBar: {
    flexGrow: 1,
    backgroundColor: "#fff",
    color: "gray",
    position:"sticky",
    top:0
  },
  appBarTab: {
    fontSize: 25, 
    textTransform: "none",
    color: "#5e5e5e",
    opacity: 1,
    fontWeight: 400,
  },
  appBarTabSelected: {
    color: "#3a2193",
    fontWeight: 500
  },
  technicalLabel: {
    padding: '10px',
    fontWeight: '700'
  },
  grid: {
    minWidth: '30%',
    display: 'flex',
    fontSize: "15px",
    marginLeft: '50px'
  },
  search: {
    position: "relative",
    borderRadius: 20,
    backgroundColor: "#f0f0f2",
    "&:hover": {
      backgroundColor: "#f0f0f2"
    },
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",  
    [theme.breakpoints.up("md")]: {
      width: "70%"
    },
    marginBottom: '20px'

  },
  searchIcon: {    
    padding: theme.spacing(0, 2),
    color: "#A2A2A2",
    height: "50px",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  searchSection: {
    display: 'flex',     
    flexDirection: 'column',     
    [theme.breakpoints.up("md")]: {      
      flexDirection: 'row',     
    },
  },
  inputInput: {
    color: "#848484",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    fontSize: "15px",    
    [theme.breakpoints.up("md")]: {
      width: "80ch"
    },
    width: "5ch"
  },
  sectionBreak: {
    color: "#c4c4c4",
    margin: 0,
    marginTop: "-1px",
    borderBottom: 0
  }
});


export default withStyles(styles)(Home);
