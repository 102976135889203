import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import defaultImage from "../assets/missing_image.png"
import ResponsiveDialog from "./ArticleModal";
import LoadingSectionCard from './LoadingSectionCard';
import TitleAndFavicon from "./TitleAndFavicon.js"

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    marginBottom: "85px",
    marginLeft: '30px',
    [theme.breakpoints.up('xs')]: {
      marginLeft: 0,
    }
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  gridItem: {
    display: "block",
    marginLeft: "0px"
  },
  gridItemRight: {
    display: "block",
    paddingLeft: "50px"
  },
  row: {},
  grid: {
    alignContent: "space-between",
    marginBottom: '30px'
  },
  newsIcon: {
    margin: '100px',
    padding: '10px',
    width: 'auto',
  },
  newsFeedItem: {
    height: '450px',
    width: '350px',
    [theme.breakpoints.up("xs")]: {
      width: "100%"
    },
    margin: 'auto',
    position: 'relative',
    boxSizing: "border-box",
    borderRadius: "8px",
    border: "1px solid #FFFFFF",
    cursor: "pointer",
    boxShadow: "0px 5px 9px 2px rgba(0,0,0,0.1)",
    transition: "100ms",
    '&:hover': {
      border: "1px solid #7B61FF",
      boxShadow: "0px 5px 12px 10px rgba(0,0,0,0.1)",
      transform: "translateY(-5px)",
    }
  },
  newsFeedImage: {
    height: '160px',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: "contain",
    backgroundColor: "#efefef" //if gray background on small images
  },
  newsFeedDescriptionContainer: {
    overflow: "hidden"
  },
  loadMore: {
    "&:hover:not(.Mui-disabled)": {
      cursor: 'pointer'
    },
    padding: '10px',
    fontWeight: 900,
    marginTop: '50px',
    width: "100%",
    justifyContent: 'center',
    textAlign: 'center'
  },
  readmoreButton: {
    color: '#3a2193',
    fontSize: "16px",
    fontWeight: 400,
    textTransform: 'none',
    position: "absolute",
    bottom: 5,
    right: 10
  }, 
  fadeOut: {
    position: "absolute",
    top: "70%",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1) 70%)",
    borderRadius: "0px 0px 8px 8px"
  },
  pageButton: {
    padding: '0px',
    margin: '0px',
    minWidth: '20px'
  },
  pagination: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  newsFeedPlaceholderBox: {
    backgroundColor: "#dbdadb",
    marginBottom: "6px"
  },
  newsFeedPlaceholderButton: {
    backgroundColor: "#dbdadb",
    width: "108px",
    height: "20px",
    position: "absolute",
    bottom: 13,
    right: 13
  },
  newsFeedPlaceholderProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

class SectionCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      readmore: false,
      loadMax: 15,
      groupSize: 15,
      paginationSize: 5,
      modalOpen: false,
      modalData: {}
    }
    const option = {
      root: null,
      rootMargin: "0px 0px 0px 0px",
      threshold: 0
    };
    this.observer = new IntersectionObserver((entries) => { this.setState({loadMax: this.state.loadMax + 15}); }, option)
    this.itemRef = React.createRef()
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleLoadLess = this.handleLoadLess.bind(this);
    // this.getPaginationGroup = this.getPaginationGroup.bind(this);
    // this.changePage = this.changePage.bind(this);
  }

  // --------------------------------------------------
  // Unused handlers for pagination  
  // --------------------------------------------------
  // getPaginationGroup = () => {
  //   let start = Math.floor((this.state.loadMax - 1) / this.state.groupSize) ;
  //   return new Array(this.state.paginationSize).fill().map((_, idx) => start - 2 + idx + 1);
  // };

  // changePage(event) {
  //   const pageNumber = Number(event.target.textContent);
  //   if (pageNumber * this.state.groupSize < this.props.links.length) {
  //     this.setState({loadMax: this.state.groupSize * pageNumber})
  //     window.scrollTo(0, 0)
  //   }
  // }

  openModal(modalData) {
    this.setState({modalOpen: true, modalData: modalData})
  }

  handleLoadMore() {
    if(this.props.links.length > this.state.loadMax) {
      // this.setState({loadMax: this.state.loadMax + this.state.groupSize})
      // window.scrollTo(0, 0)
    } else {
      console.log("Max load reached")
    }
  }

  handleLoadLess() {
    if(this.state.loadMax > this.state.groupSize) {
      this.setState({loadMax: this.state.loadMax - this.state.groupSize})
      window.scrollTo(0, 0)
    } else {
      console.log("Min load reached")
    }
  }

  attachItemRef() {
    if (this.itemRef.current) {
      this.observer.observe(this.itemRef.current)
      return true
    }
    return false
  }

  componentDidMount() { 
    this.intervalID = setInterval(() => {
      if (this.attachItemRef()) {
        clearInterval(this.intervalID)
      }
    }, 100)
  }

  render() {
    const {classes} = this.props;
    let docs = [];
    // for (let step = this.state.loadMax - this.state.groupSize; step < Math.min(this.state.loadMax, this.props.links.length); step++) {
    for (let step = 0; step < Math.min(this.state.loadMax, this.props.links.length); step++) {
      docs.push(this.props.links[step]);      
    }
    // resolve an odd issue
    docs.forEach(d => {
      if (typeof d.description == "object") {
        d.description = d.description.summary_text
      }
    })

    if (this.props.loading) {
      return (
        <div className={classes.root}>
        <Container maxWidth="lg">
        <Grid container spacing={1} className={classes.grid} >
          <LoadingSectionCard classes={classes}/>
          <LoadingSectionCard classes={classes}/>
          <LoadingSectionCard classes={classes}/>
          <LoadingSectionCard classes={classes}/>
          <LoadingSectionCard classes={classes}/>
          <LoadingSectionCard classes={classes}/>
        </Grid>
        </Container>
        </div>
        )
    }

    if(docs.length > 0) {
      return (
        <div className={classes.root}>
        <Container maxWidth="lg">
        <Grid container spacing={1} className={classes.grid} >
          {docs.map((value, idx) => {
            return(
              <Grid item md={4} sm={6} xs={12} key={idx}>
                <Paper elevation={0} className={classes.newsFeedItem} onClick={() => {this.openModal(value)}}>
                  <div style={{padding:'20px', height:"100%", display: "flex", flexDirection: "column", overflow: 'hidden'}}>
                      <img 
                        className={classes.newsFeedImage} 
                        src={value.imageURL} 
                        style={{display: "block"}} 
                        onError={(e)=> {e.target.onerror = null; e.target.src = defaultImage}} 
                        alt="Failed to load"
                      />
                      <TitleAndFavicon site={value} />
                      <Typography variant={'h6'} style={{color: "#4d30be", fontWeight: 700, lineHeight:1.2, marginTop: 10}}>{value.title}</Typography>
                    <div className={classes.newsFeedDescriptionContainer}>
                      {value.description ? <Typography style={{height: "100%"}}>{value.description}</Typography> : <Typography style={{fontStyle: "italic"}}>No summary provided</Typography>}
                    </div>
                  </div>
                  <div className={classes.fadeOut}>
                    <Button className={classes.readmoreButton} href={value.url} onClick={e => {e.preventDefault(); e.stopPropagation(); window.open(value.url, '_blank')}} key={value}>Read Article ▶</Button>
                  </div>
                </Paper>
              </Grid>
            )
            })
            }
            <Grid className={classes.loadMore} item md={12} ref={this.itemRef}>
                {/*<div className={classes.pagination}>
                  <Button
                    color="secondary"
                    onClick={this.handleLoadLess}
                  >
                    ◀
                  </Button>

                  {this.getPaginationGroup().map((item, index) => (
                    <Button 
                      variant="text" 
                      color={item === this.state.loadMax / this.state.groupSize ? "primary" : "secondary"}
                      key={index}
                      onClick={this.changePage}
                      style={{
                        display: item >= 1 ? "inline" : "none"
                    }}
                      className={classes.pageButton}
                    >
                      <span>{item}</span>
                    </Button>
                  ))}

                  <Button
                    onClick={this.handleLoadMore}
                    color="secondary"
                  >
                    ▶
                  </Button>
                </div> */}
                <Typography variant="h5" color="primary">End of Database</Typography>
            </Grid>
          </Grid>
        </Container>
        {this.state.modalOpen && <ResponsiveDialog data={this.state.modalData} onClose={() => {this.setState({modalOpen: false})}}/>}
        </div>
      );
    } else {
      return(
        <div></div>
      )
    }
  }
}

export default withStyles(styles)(SectionCards);
