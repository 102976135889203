import React from "react";
//import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';

export let openDialog = 0;

export default function ResponsiveDialog(video) {
    const [open, setOpen] = React.useState(false);
    const [main, setMain] = React.useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
  
    openDialog = handleClickOpen;
  
    return (
      <div>
        <Dialog
          fullScreen={fullScreen} 
          maxWidth="md"
          style = {{maxHeight: 1000}}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {video.title}
          </DialogTitle>
          <DialogContent
            style={{justifyContent: "center"}}
          >
          <iframe style={{
              marginTop: "auto",
              marginBottom: '40px',
              marginLeft: "auto",
            }}
            title={`YouTube Embed`}
            width= {fullScreen ? "100%" : "900"} height= {fullScreen ? "550" : "506"}
            key={main}
            src={video.videoList[main].url + "?autoplay=1"} frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div style={{display:"flex", flexWrap:"wrap"}}>
            {
                renderVideoThumbs(video.videoList, setMain, main)
            }
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
  
  function renderVideoThumbs(videoList, setMain, main) {
    let returnList = videoList.map((video,i) => {
      return (
        <div style={{
          display:"flex",
          width:240,
          marginRight: '20px', 
          marginBottom: '20px',
          flexDirection:"column",
        }} key={i}>          
          <div style={{background: `url(https://i.ytimg.com/vi/${video.video_id}/sddefault.jpg#404_is_fine) center no-repeat`, backgroundSize: `240px 180px`, height:134, width:240, opacity: (i === main) ? 0.3 : 1, textAlign: "center"}} className='thumbnail' onClick={() => setMain(i)}></div>
          <div style={{position: 'relative'}}>
            <p style={{
              fontWeight:1000,
              color:"black",
              display: (i === main) ? "block" : "none",
              marginTop: '-75px',
              marginLeft: '95px',
              position: 'absolute',
              }}>Playing...</p>
          </div>
          
          <p style={{
            fontWeight:700,
            color:"black" 
          }}>{video.topic}</p>
        </div>
      );
    });
  
    return returnList;
  }
