import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Navigation from "./components/navigation";
import StickyFooter from "./components/StickyFooter";
import ScrollToTop from "./util/ScrollToTop"
import Home from "./components/Home"
import About from "./components/About";

// For database lookups
// import * as db from './services/datastore';

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <CssBaseline />
        <Navigation />
        <div>
          <Switch>
            <Route path="/Video">
              <Home links={this.props.links} value={1}/>
            </Route>
            <Route path="/About">
              <About />
            </Route>
            <Route path="/">
              <Home links={this.props.links} value={0}/>
            </Route>
          </Switch>
        </div>
        <StickyFooter />
      </Router>
    );
  }
}

export default App;
