import React from "react";
import { withRouter } from 'react-router-dom';

import Link from "@material-ui/core/Link"
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
// import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import logo_white from "./assets/logo_white.svg";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    display: "none",
    textTransform: "none",
    fontSize: "20px",
    width: "10%",
    color: "black",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        cursor: "pointer"
      },
      display: "flex"
    }
  },
  search: {
    position: "relative",
    margin: "auto",
    borderRadius: 16,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    //marginLeft: 0,
    width: "60%",
    [theme.breakpoints.up("xs")]: {
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    color: "#A2A2A2",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    fontStyle: "normal"
  },
  inputInput: {
    color: "#848484",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    fontSize: "13px",
    [theme.breakpoints.up("sm")]: {
      width: "55ch",
      "&:focus": {
        width: "55ch"
      }
    }
  },
  toolBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "960px",
    },
    margin: "auto"
  },
  clickButton: {
    width: "75px",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "20px",
    height: "30px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "12px",
    color: "white", //hot pink
    "&:hover": {
      backgroundColor: "#A2A2A2"
    },
    textTransform: "none"
  }
});

class SearchAppBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: "",
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log(this.state.query);
    }
  }

  render() {
    // const { history } = this.props;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" elevation={0} style={{
          backgroundColor: "#442c99",
          display: "flex"
        }}>
          <Toolbar className={classes.toolBar}>
            <Link href="/" className={classes.title}>
              <img style={{
                margin: "auto",
                width: "92px"
              }} src={logo_white} alt='' />
            </Link>
            <Button href={"./About"} className={classes.clickButton}>
              About
            </Button>
            <Button href="https://teachaids.org/terms-use/" className={classes.clickButton}>
              Terms
            </Button>
            <Button href="https://teachaids.org/privacy-policy/" className={classes.clickButton}>
              Privacy
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SearchAppBar));
