import React from 'react'

import {withStyles} from "@material-ui/core/styles"
import {Typography} from "@material-ui/core"

const getFavicon = site => {
  return "http://www.google.com/s2/favicons?domain=" + (new URL(site.url)).hostname
}

const getSiteTitle = site => {
  // Once the site titles are in the DB, change this to `site.siteTitle` or the like
  return (new URL(site.url)).hostname.replace("www.","")
}

const styles = {
  sourceContainer: {
    marginTop: "10px",
  },
  favicon: {
    width: "16px",
    height: "16px",
    transform: "translateY(3px)",
    marginRight: "5px"
  },
  siteTitle: {
    color: "#5e5e5e",
    fontSize: "14px",
    fontWeight: "500"
  }
}

const TitleAndFavicon = props => {
  return (
  <div className={props.classes.sourceContainer}>
    <Typography variant={'body1'} className={props.classes.siteTitle}><img src={getFavicon(props.site)} className={props.classes.favicon} alt={""} /> {getSiteTitle(props.site)}</Typography>
  </div>
  )
}

export default withStyles(styles)(TitleAndFavicon) 
