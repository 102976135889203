import React, {useEffect} from "react";
//import Paper from "@material-ui/core/Paper";
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, DialogContent, Typography} from '@material-ui/core';
import defaultImage from "../assets/missing_image.png"
import closeModalImage from "../assets/close_modal.png"
import TitleAndFavicon from "./TitleAndFavicon.js"

function ResponsiveDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
      const handleKeypress = e => {
        if (e.key === "Escape") {
          props.onClose()
        }
      }
  
      const handleClick = e => {
        if (e.target.className === "MuiDialog-container MuiDialog-scrollPaper") {
          props.onClose()
        }
      }

      window.addEventListener("keyup", handleKeypress)
      window.addEventListener("click", handleClick)
      return () => {
        window.removeEventListener("keyup", handleKeypress)
        window.removeEventListener("click", handleClick)
      }
    })

    const { classes } = props

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          maxWidth={"sm"}
          scroll={"paper"}
          open={true}
          //onClose={props.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <div style={{padding: "16px 0 0 24px"}}>
            <TitleAndFavicon site={props.data} />
          </div>
          <Typography id="responsive-dialog-title" className={classes.dialogTitle}>
            {props.data.title}
          </Typography> <img src={closeModalImage} className={classes.closeImg} alt="Close Dialog" onClick={props.onClose} />
          <DialogContent>
            <div width="100%">
              <img alt={props.data.title} className={classes.articleImg} src={props.data.imageURL}  onError={(e)=> {e.target.onerror = null; e.target.src = defaultImage}}/>
            </div>
            {props.data.description ? <p className={classes.descriptionText}>{props.data.description}</p> : <p className={classes.descriptionText} style={{fontStyle: "italic"}}>No summary provided</p>}
            <p className={classes.readFullArticleContainer}><a target="_blank" rel="noopener noreferrer" href={props.data.url} className={classes.readFullArticle}>Read Full Article   ▶</a></p>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

const styles = theme => ({
  dialogTitle: {color: "#4D30BE", fontSize: "29px", fontWeight: 700, padding: "16px 24px"},
  closeImg: {
    position: "absolute",
    top: 16,
    right: 16,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "24px",
      height: "24px"
    }
  },
  articleImg: {width: '100%', height: '100%', display: "block", padding: "30px"},
  descriptionText: {color: "rgba(0,0,0,1)", fontSize: "16px"},
  readFullArticleContainer: {
    textAlign: "right", 
    whiteSpace: "pre"
  },
  readFullArticle: {
    textDecoration: "none",
    color: "#3A2193"
  }
})

export default withStyles(styles)(ResponsiveDialog)
