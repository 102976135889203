import React from "react";
//import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Container, Link, Paper} from '@material-ui/core';
import videoList from "./videosList"
import PDF from "../assets/PDF.svg"
import ResponsiveDialog, {openDialog} from "./VideoModal";
// import Spotify from "./assets/podcast/spotify.png"
// import Apple from "./assets/podcast/apple.png"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0"
  },
  headerVideo: {
    paddingTop: '50px',
    color: "#BE2E3B",
    fontFamily: ' Roboto',
    fontWeight: "800"
  },
  bio: {
    marginTop: '20px',
    fontWeight: "bold"
  },
  headerGeneral: {
    paddingTop: '30px',
    align: 'left',
    variant: 'h3',
    fontFamily: ' Roboto',
    fontWeight: "800",
    color: "#BE2E3B",
  },
  body: {
    color: 'black',
    aligh: 'left',
    paragraph: 'true',
    variant: 'body1',
  },
  aboutBody: {
    paddingBottom: "50px",
    color: 'black',
    aligh: 'left',
    paragraph: 'true',
    variant: 'body1',
    [theme.breakpoints.up('sm')]: {
      maxWidth: "70%"
    },
  },
  thumbnail: {
    cursor: "pointer", 
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  linkBack: {
    paddingTop: '15px',
    color: 'red',
    aligh: 'left',
    paragraph: 'true',
    variant: 'body1',
  },
  bioH2: {
    fontStyle: "italic"
  },
  icon: {
    width: "15px",
    height: "15px",
    marginTop: "auto",
    marginBottom: "auto"
  },
  teacherGuide: {
    color: "#0B98FF",
    marginTop: "auto",
    marginBottom: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline !important",
    }
  },
  readMore: {
    color: "#BE2E3B",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    }
  },
  paperContent: {
    padding: "20px"
  },
  paper: {
    marginBottom: "20px",
  }
}));


export default function Video() {
  let videos = videoList
  const classes = useStyles();
  let shorts = videos.map((vid, i) => {
    return {
      short: vid.desc.split(" ").slice(0, 20).join(" ").split("\n").join("<br />") + "...",
      long: vid.desc.split("\n").join("<br />"),
      currentShort: true
    }
  });

  return (
    <Container style={{ display: "flex", marginLeft: 0 }} maxWidth="md">
      <Container className={classes.root}>
        {
          videos.map((vid, i) => {
            return (
              <Paper className={classes.paper} key={i} elevation={3}>
                <Grid container direction="row" className={classes.paperContent}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" className={classes.headerGeneral}>
                      Episode {videos.length - i}: {vid.title}
                    </Typography>
                    <Typography style={{
                      paddingBottom: "30px"
                    }} className={classes.aboutBody}><span id={`description-${i}`} dangerouslySetInnerHTML={{ __html: shorts[i].short }}></span><br /><Link onClick={() => {
                      shorts[i].currentShort = !shorts[i].currentShort
                      videos.map((v, i) => {
                        document.getElementById(`description-${i}`).innerHTML = shorts[i].currentShort ? shorts[i].short : shorts[i].long
                        document.getElementById(`readMore-${i}`).innerHTML = shorts[i].currentShort ? "Read More ▼" : "Read Less ▲"
                        return 0
                      })
                    }} className={classes.readMore} id={`readMore-${i}`}>Read More ▼</Link></Typography>
                    {
                      ResponsiveDialog(vid)
                    }
                    <Link target="_blank" href={vid.teachersGuide}>
                      <Grid container className={classes.teacherGuide}>
                        <img alt="Pdf Icon" src={PDF} style={{ width: "20px", marginTop: "auto", marginBottom: "auto" }} />
                        <Typography style={{ marginLeft: "10px", }}>CoviDB Teacher's Guide.pdf</Typography>
                      </Grid>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} style={{
                    paddingTop: "30px"
                  }}>
                    <img alt={`Episode ${i} YouTube Embed`} title={`Episode ${i} YouTube Embed`} src={vid.thumb} onClick={openDialog} className={classes.thumbnail}/>
                  </Grid>
                </Grid>
              </Paper>
            )
          })
        }
        {/* <Typography id="about" variant="h6" className={classes.headerGeneral}>About the CoviDB Speaker Series</Typography>
        <Typography className={classes.aboutBody}>
          In partnership with the Stanford Program on International and Cross-Cultural Education (SPICE), Stanford’s Center for Innovation in Global Health (CIGH) and the Institute for Global Health Sciences (IGHS) at UCSF, TeachAids has launched the CoviDB Speaker Series. Our aim is to bring leading global experts in medicine, public health, technology, economics, and other subjects and answer more detailed questions relating to COVID-19 than generally appear in mainstream media outlets. Each speaker series episode will be coupled with special Teacher's Guide developed and distributed by SPICE to their network of more than 25,000 teachers across the United States.
          </Typography> */}
      </Container>
    </Container>
  );
}
