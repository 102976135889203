import React from 'react'
import { Grid, Paper, CircularProgress } from "@material-ui/core";

function LoadingSectionCard(props) {
  return (
    <Grid item md={4} sm={6} xs={12} >
      <Paper elevation={0} className={props.classes.newsFeedItem}>
        <div style={{padding:'20px', height:"100%", display: "flex", flexDirection: "column", overflow: 'hidden'}}>
            <div className={props.classes.newsFeedPlaceholderBox} style={{width: "100%", height: "160px", marginBottom: "12px"}}>
              <div className={props.classes.newsFeedPlaceholderProgress}>
                <CircularProgress thickness={5}/>
              </div>
            </div>
            <div className={props.classes.newsFeedPlaceholderBox} style={{width: "90%", height: "20px"}}></div>
          <div className={props.classes.newsFeedDescriptionContainer}>
            <div className={props.classes.newsFeedPlaceholderBox} style={{width: "40%", height: "20px"}}></div>
          </div>
        </div>
        <div className={props.classes.fadeOut}>
          <div className={props.classes.newsFeedPlaceholderButton}></div>
        </div>
      </Paper>
    </Grid>);
}

export default LoadingSectionCard