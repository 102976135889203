import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import SPICE from "./assets/logos/spice.svg";
// import FSI from "./assets/logos/fsi.svg";
// import UCSF from "./assets/logos/ucsf.svg";
// import GLOBAL from "./assets/logos/globalhealth.svg";
// import logo from "./assets/logo-color.svg";
// import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#4C3B92",
    position: "fixed",
    padding: "10px 24px",
    bottom: 0,
    left: 0,
    width: "100vw"
  },
  list: {
    color: "#FFFFFF",
    margin: "4px",
    fontSize: "14px",
    whiteSpace: "pre",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px"
    }
  },
  lineBreak: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  }
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.list}>© 2022 TeachAids. All rights reserved. <a style={{color:"#FFFFFF"}} href="https://teachaids.org/privacy-policy/">Privacy Policy.</a> <br className={classes.lineBreak}/>All works available under the Creative Commons License and <a style={{color:"#FFFFFF"}} href="https://teachaids.org/terms-use/">Terms of Use.</a>
      </p>
    </div >
  );
}

