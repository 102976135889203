import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
// import logo_color from "./assets/logo_color.svg"
// import Button from '@material-ui/core/Button';

// var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// var variablepadding = "15";
// if (isMobile) { var variablepadding = "1"; }

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 10),
    // display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
  },
  headerAbout: {
    paddingTop: "50px",
    color: "#BE2E3B",
    align: "left",
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true,
    h1: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      lineHeight: "1.2em",
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: "normal",
      lineHeight: "1.2em",
    },
  },
  bio: {
    marginTop: "20px",
    fontWeight: "bold",
  },
  headerGeneral: {
    paddingTop: "30px",
    color: "black",
    align: "left",
    variant: "h3",
    //component: 'h3'??
  },
  body: {
    color: "black",
    aligh: "left",
    paragraph: "true",
    variant: "body1",
  },
  linkBack: {
    paddingTop: "15px",
    color: "red",
    aligh: "left",
    paragraph: "true",
    variant: "body1",
  },
  bioH2: {
    fontStyle: "italic",
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <box>
      <div>
        <Paper
          className={classes.root}
          style={{ padding: "10% auto", height: "auto" }}
        >
          <Typography variant="h4" className={classes.headerAbout}>
            About
          </Typography>
          <Typography variant="h5" className={classes.headerGeneral}>
            What Is CoviDB?
          </Typography>
          <Typography className={classes.body}>
            CoviDB is a community-edited, expert-curated, platform organizing
            resources across a comprehensive set of topics relating to the
            COVID-19 (coronavirus) pandemic for public use.
          </Typography>
          <br />
          <Typography className={classes.body}>
            CoviDB aims to bring trustworthy, expert-vetted, COVID-19
            information to the public. When the pandemic began, and accurate
            information was sorely needed, our community of experts
            hand-selected reliable content to be shared on this website. Over
            the years, with our global community producing more content, we
            created a new platform which allows us to bring more COVID-19 news
            that experts trust to the public faster. We automatically pull these
            articles into the new database and allow users to filter articles.
            We also use machine learning to help generate summaries of the
            articles that allow the reader to understand the article’s content
            at a glance. This is in contrast to headlines that are often
            clickbait and summaries that bury the lede for the most important
            details.
          </Typography>
          <Typography variant="h5" className={classes.headerGeneral}>
            Who Created This Site?
          </Typography>
          <Typography className={classes.body}>
            The site was launched by{" "}
            <a href="https://teachaids.org">TeachAids</a> working with faculty,
            students, and alumni from{" "}
            <a href="https://stanford.edu">Stanford University</a>, the{" "}
            <a href="https://mit.edu">Massachusetts Institute of Technology</a>,{" "}
            <a href="https://harvard.edu">Harvard University</a>,{" "}
            <a href="https://dartmouth.edu">Dartmouth College</a>, and{" "}
            <a href="https://colorado.edu">
              The University of Colorado at Boulder
            </a>
            , among others around the world. In addition, we've partnered with
            leadership from the{" "}
            <a href="https://spice.fsi.stanford.edu/">
              Stanford Program on International and Cross-Cultural Education
              (SPICE)
            </a>
            ,{" "}
            <a href="https://globalhealth.stanford.edu/">
              Stanford's Center for Innovation in Global Health (CIGH)
            </a>{" "}
            and the{" "}
            <a href="https://globalhealthsciences.ucsf.edu/">
              Institute for Global Health Sciences (IGHS)
            </a>{" "}
            at the{" "}
            <a href="https://www.ucsf.edu/">
              University of California, San Francisco
            </a>
            , to help develop and distribute our content, programming, and study
            materials globally. The original site was conceived, built, and
            launched in one week, by a team working entirely remotely while
            social distancing. We hope this site can help journalists, students,
            business leaders, and everyone else that is looking for reliable
            information about COVID-19.
          </Typography>

          <Typography variant="h5" className={classes.headerGeneral}>
            Special Thanks
          </Typography>
          <Typography className={classes.body}>
            Our heartfelt thanks to the dedicated team of volunteers who pulled
            together from around the world and have collectively dedicated
            thousands of hours to build this database. This team includes: Diana
            Anthony, Venkat Arun, Taggart Bonham, Christine Chen, Daniel
            Daneshvar, Shuman Ghosemajumder, Micah Gilbert, Mary Gillett,
            Christin Glorioso, Anne Gould, Federico Reyes Gomez, Shravya
            Gurrapu, Paitra Houts, Liz Hurder, Sarrah Hussain, Govind Jeevan,
            Michelle Kafka, Aparna Khare, Pranavi Kethanaboyina, Adam Keppler,
            Francesca Kim, Peter Knowles, Christian Kontaxis, Alex Koritz,
            Sriram Krishnan, Brice Jansen, Sergio Luna, Nicolas Machado, Nruthya
            Madappa, Lauren McLaughlin, Elena Mosse, Surabhi Mundada, Suraj
            Venkata Raman, Anima Shrestha, Piya Sorcar, Kevin Su, Payal Sutton,
            Anafia Thomas, Alex Tsai, Randy Warren, Emily Yuan, Jason Zhao,
            Anaum Ahmad, Sepehr Asgari, Nathaniel Braun, Jessica Boyle, Kirsten
            Blanco, Amy Bugwadia, Karen Chen, Ryan Crowley, Ritesh Dontula,
            Isabella Fish, Joshua Garnett, Megha Govindu, Shravya Gurrapu,
            Rachel Hyzny, Roberto Lama, Daria Lenz, Les Levy, Yash Mahajan,
            Elodie Mortimer, Shayna Naranjo, Cali Nguyen, Leila Orszag, Siri
            Peddineni, Sierra Porter, Neel Redkar, Dylan Ryder, Agrima Seth,
            Sanjana Taware, Ciauna Tran, Rohan Tirumale, Sethuraman T V, Shriya
            Vejendla, Tanya Vohra, Kiran Wijesooriya, and Nathan Yap.
          </Typography>

          <Typography variant="h5" className={classes.headerGeneral}>
            Advisors
          </Typography>

          <Typography className={classes.body}>
            <div className={classes.bio}>Scott Anderson</div>
            <div className={classes.bioH2}>
              Chief Customer Officer at SyncThink
            </div>
            <div>
              Scott Anderson is a Chief Customer Officer at SyncThink. Before
              joining SyncThink in 2017, Anderson spent 10 years with Stanford
              University as the lead Director of Athletic Training. During his
              tenure, Anderson worked diligently to build and grow the
              infrastructure of the sports medicine program. As former chair of
              the Pac-12 Sports Medicine committee, he has been influential in
              conference wide research focused on improving student-athlete
              health and wellness. He is also an internationally recognized
              speaker and holds a number of designations including a
              certification from the National Athletic Trainers’ Association. He
              is an approved clinical instructor (ACI), and maintains
              certifications in the functional movement screen (FMS), selective
              functional movement assessment (SFMA) and Dynamic Neuromuscular
              Stabilization (DNS).
            </div>

            <div className={classes.bio}>Diana Anthony</div>
            <div className={classes.bioH2}>
              Program Manager, Stanford Neurosurgical Simulation and Virtual
              Reality Center
            </div>
            <div>
              Diana Anthony currently serves the Stanford University School of
              Medicine Neurosurgery Department as the Program Manager of the
              Neurosurgical Simulation and Virtual Reality Center. She supports
              the team by reconstructing patient-specific anatomy in 360
              degrees/VR environments to help with patient education and
              engagement, surgical planning, and intraoperative guidance. The
              “Aha!” moment patients experience when they see their anatomy this
              way, and the unique beauty that comes from the creativity of
              designing the reconstruction of all the complexities of the human
              brain are her two favorite experiences of the job. Anthony also
              has experience managing the operations of a global non-profit, the
              Range of Motion Project, in Quito, Ecuador and has served her
              current, San Francisco community through being a public
              middle-school educator of science. Empowering people to live
              independently and empowering kids to study the world around them
              with a certain scientific curiosity are also passions of hers. In
              her free time, Anthony skis, climbs, backpacks, dives, runs,
              paints, and reads voraciously!
            </div>

            <div className={classes.bio}>Dr. Terrence Blaschke</div>
            <div className={classes.bioH2}>
              Senior Program Officer in Clinical Pharmacology, Bill and Melinda
              Gates Foundation
            </div>
            <div>
              Dr. Terrence Blaschke is a Senior Program Officer in Clinical
              Pharmacology at the Bill and Melinda Gates Foundation, and
              Professor Emeritus of Medicine and Clinical Pharmacology at
              Stanford University School of Medicine. His research examines the
              clinical pharmacology of drugs used with HIV-infected patients.
              His research focuses on the quality of antiretroviral drugs and
              the ability of patients’ to access drugs. In addition, Dr.
              Blaschke collaborates with the AMPATH program in Eldoret, Kenya, a
              comprehensive initiative to build the public sector’s HIV
              prevention and treatment capacity in urban and rural settings. He
              is on the Organizing Committee for a workshop on Clinical
              Pharmacology in HIV therapy. He holds an M.D. from Columbia
              University.
            </div>

            <div className={classes.bio}>Bernadette Clavier</div>
            <div className={classes.bioH2}>
              Director, Center for Social Innovation and Emerging Projects
            </div>
            <div>
              Ms. Bernadette Clavier is the director of the Center for Social
              Innovation at Stanford GSB. The center educates insightful leaders
              for social and environmental change. Prior to this, she was in
              charge of the center’s social innovation programs and before that
              its communications, digital strategy, and conferences. Ms. Clavier
              came to the United States from France, where she worked as vice
              president of seasonal products for E. Leclerc, the French retail
              chain equivalent of Wal-Mart. She was a Founding Board Member of
              Spring Impact, and a Partner at Silicon Valley Social Venture
              Fund. She sits on the board of ICSF, a nonprofit consulting
              organization that supports the scaling of social ventures. She
              holds an MBA from HEC Paris, speaks French and English, and in her
              spare time she advises social entrepreneurs, advocates for autism
              spectrum disorders awareness, and works as part of the Climate
              Reality Project team.
            </div>
            <div className={classes.bio}>Dr. Shelley Goldman</div>
            <div className={classes.bioH2}>
              Associate Dean for Faculty Affairs and for Student Affairs,
              Graduate School of Education, Stanford University
            </div>
            <div>
              Dean Shelley Goldman is the Associate Dean of Students & Faculty
              Affairs at the Graduate School of Education at Stanford
              University. She is also a Professor of Learning Sciences &
              Technology Design at the School of Education and Professor of
              Mechanical Engineering (by courtesy). Her interest in educational
              anthropology drives her research on real-world contexts of
              learning. At Stanford’s d.school K-12 Lab, she leads a project to
              learn how to effectively implement design thinking as a tool for
              classroom learning. Dean Goldman is currently a principal
              investigator for the Stanford Education for Global HIV/AIDS,
              Infectious Disease, and Epidemics project. She holds an Ed.D. from
              Columbia University.
            </div>

            <div className={classes.bio}>Dr. Seble Kassaye</div>
            <div className={classes.bioH2}>
              Associate Professor, Department of Medicine/Infectious Diseases,
              Georgetown University
            </div>
            <div>
              Dr. Seble Kassaye is trained in Internal Medicine, Pediatrics,
              Infectious Diseases, and Epidemiology. Her research interests
              include issues related to mother-to-child transmission, HIV
              prevention, drug resistance, and the monitoring of HIV treatment.
              She has worked as an instructor of Infectious Diseases and
              Geographic Medicine at Stanford University and has practiced
              medicine as an Infectious Diseases physician at Stanford Hospital
              and the San Mateo County Medical Center. She is now working as an
              Associate Professor at the Georgetown University School of
              Medicine. She received her M.S. in Epidemiology from Stanford
              University and her M.D. from the University of Chicago.
            </div>

            <div className={classes.bio}>Dr. David Katzenstein</div>
            <div className={classes.bioH2}>
              Professor of Infectious Diseases Emeritus at the Stanford School
              of Medicine
            </div>
            <div>
              Dr. David Katzenstein was a Professor of Infectious Diseases
              Emeritus at the Stanford School of Medicine. He co-founded the
              Principal Investigator of the Zimbabwe AIDS Prevention Project, a
              community-based research organization. He conducted HIV-related
              research throughout the United States, Africa, and Asia. His
              laboratory and clinical efforts span the AIDS Clinical Trials
              Group and HIV Prevention Trials Network. He focused on prevention
              of viral evolution, mother-to-child-transmission, and drug
              resistance in the context of scaling-up antiretroviral drug
              treatment for AIDS in Africa and Asia. He held an M.D. from the
              University of California San Diego School of Medicine.
              <br />
              <br />
              CoviDB for Kids is dedicated in memory of Dr. David Katzenstein.
              An ardent believer that education was key to solving global health
              challenges, he was an active volunteer with TeachAids since it's
              founding in 2009, and helped launch the CoviDB platform. He passed
              away on January 25, 2021 of COVID-19 in Zimbabwe surrounded by
              medical staff he had trained and mentored, pursuing his life's
              mission, which was to eliminate suffering.
            </div>

            <div className={classes.bio}>Aparna Khare</div>
            <div className={classes.bioH2}>Speech Scientist, Amazon</div>
            <div>
              Aparna Khare is a Speech Scientist at Amazon working on speech and
              language technologies. She was part of the science team that
              launched the Amazon Echo, a first of its kind speaker that uses
              far field speech recognition. Prior to that, she was a software
              engineer at Cisco where she worked on developing a speaker
              identification system. At TeachAids, she manages several
              programming elements and supported in the development and launch
              of 14 animated software versions. She is an active member of the
              Stanford chapter of Asha for Education, a nonprofit focused on
              bringing about socio-economic change in India by means of
              education. She holds an M.S. from Texas A&M University.
            </div>

            <div className={classes.bio}>Dr. Paul Kim</div>
            <div className={classes.bioH2}>
              Chief Technology Officer and Assistant Dean, Graduate School of
              Education, Stanford University
            </div>
            <div>
              Dr. Paul Kim is the Chief Technology Officer and Assistant Dean of
              the Graduate School of Education at Stanford University. He has
              been leading various academic technology initiatives and teaching
              graduate level courses related to learning technology and digital
              innovations at Stanford since 2001. His courses focus on
              contextualized innovations in education, mobile empowerment
              design, and enterprising higher education systems. He is currently
              one of leading researchers for Programmable Open Mobile Internet,
              a National Science Foundation project to develop and evaluate
              wireless mobile computing and interactive systems for K-20
              learning and assessment scenarios. He received his Ph.D. in
              Educational Technology from the University of Southern California.
            </div>

            <div className={classes.bio}>Dr. S. V. Mahadevan</div>
            <div className={classes.bioH2}>
              Professor of Emergency Medicine at the Stanford University Medical
              Center, Director of Global Affairs and Strategy at the Stanford
              University School of Medicine
            </div>
            <div>
              Dr. Mahadevan is Professor of Emergency Medicine at the Stanford
              University Medical Center and Director of Global Affairs and
              Strategy at the Stanford University School of Medicine. Dr.
              Mahadevan founded Stanford Emergency Medicine International (SEMI)
              and served as the Director of this program for 15 years.
              Previously, Dr. Mahadevan was the Medical Director and Associate
              Chief of Stanford Emergency Medicine from 2000-2012, and was the
              founding department chair of Stanford Emergency Medicine from
              2015-2017.
            </div>

            <div className={classes.bio}>Dr. Gary Mukai</div>
            <div className={classes.bioH2}>
              Director, Stanford Program on International and Cross-Cultural
              Education (SPICE)
            </div>
            <div>
              Dr. Gary Mukai is the director of the Stanford Program on
              International and Cross-Cultural Education (SPICE), Freeman Spogli
              Institute for International Studies at Stanford University. SPICE
              focuses its work in three areas: curriculum development for
              elementary and secondary schools; teacher professional
              development; and distance learning education. Before joining SPICE
              in 1988, he was a school teacher in Japan and California for ten
              years. In honor of his cross-cultural international work, the
              Japanese government awarded him with the Order of the Rising Sun,
              Gold and Silver Rays. He is a graduate of U.C. Berkeley and
              Stanford University.
            </div>

            <div className={classes.bio}>Anne Firth Murray</div>
            <div className={classes.bioH2}>
              Founding President, Global Fund for Women
            </div>
            <div>
              Anne Firth Murray, a New Zealander, is the founding president of
              the Global Fund for Women, a grant-making organization that has
              distributed more than $61 million to advance the rights of women
              and girls in 167 countries. She is the author of two books: From
              Outrage to Courage: The Unjust and Unhealthy Situation of Women in
              Poorer Countries and What They Are Doing About It and Paradigm
              Found: Leading and Managing for Positive Change. She has served on
              the boards of numerous organizations, including the African
              Women’s Development Fund, and as the chair of the Global Justice
              Center. In 2005, she was nominated for the Nobel Peace Prize as
              one of a group of women dedicated to social justice and peace.
            </div>

            <div className={classes.bio}>Dr. Kamran Naim</div>
            <div className={classes.bioH2}>Head of Open Science for CERN</div>
            <div>
              Kamran Naim is the Head of Open Science for CERN, one of the
              world's largest and most respected centres for scientific
              research. Naim has a portfolio of extensive international
              experience, working for the U.S. Department of State, USAID,
              National Academies and World Bank programs to support global
              scientific capacity building through broader access and visibility
              of research. He is currently a doctoral researcher and educator at
              the Stanford University Graduate School of Education and holds a
              Masters Degree in International Science and Technology Policy from
              the Elliott School of International Affairs of the George
              Washington University.
            </div>

            <div className={classes.bio}>Dr. Doug Owens</div>
            <div className={classes.bioH2}>
              Director of the Center for Health Policy, Stanford University
            </div>
            <div>
              Dr. Douglas Owens is the Henry J. Kaiser, Jr. Professor, and
              Director of the Center for Health Policy and of the Center for
              Primary Care and Outcomes Research at Stanford University and
              Stanford Medicine. Dr. Owens is a Professor of Medicine. He is
              also by courtesy Professor of Health Research and Policy, and
              Professor of Management Science and Engineering. Dr. Owens is the
              Vice-Chairperson of the U.S. Preventive Services Task Force. His
              research focuses on preventive and therapeutic interventions for
              various illnesses, including HIV and AIDS. Dr. Owens is a general
              internist and received an MS from Stanford University and MD from
              UCSF.
            </div>

            <div className={classes.bio}>Dr. Roy Pea</div>
            <div className={classes.bioH2}>
              David Jacks Professor of Education and the Learning Sciences,
              Stanford University
            </div>
            <div>
              Prof. Roy Pea is David Jacks Professor of Education and the
              Learning Sciences at Stanford University, Co-PI of the LIFE Center
              and Co-Director of the Human Sciences and Technologies Advanced
              Research (H-STAR) Institute. He has published widely on K-12
              learning and education, especially in STEM fostered by advanced
              technologies. He is co-author of the 2010 National Education
              Technology Plan for the US Department of Education. In 2004-2005,
              he was President of the International Society for the Learning
              Sciences. He also served from 1999-2009 as a Director for
              Teachscape, a company he co-founded with CEO Mark Atkinson. He
              received his doctorate in developmental psychology from Oxford as
              a Rhodes Scholar.
            </div>

            <div className={classes.bio}>Dr. George Rutherford</div>
            <div className={classes.bioH2}>
              Professor of Epidemiology, Head of the Division of Infectious
              Disease and Global Epidemiology at UCSF
            </div>
            <div>
              Dr. George Rutherford is the Director for the Institute for Global
              Health, the Salvatore Pablo Lucia Professor of Epidemiology,
              Preventive Medicine and Pediatrics, and the head of the Division
              of Prevention Medicine and Public Health in the Department of
              Epidemiology and Biostatistics in the School of Medicine,
              University of California, San Francisco. He is also Professor of
              Pediatrics and of Family and Community Medicine and Adjunct
              Professor of Epidemiology and Health Administration at the School
              of Public Health at the University of California, Berkeley.
              Educated at Stanford University and the Duke University School of
              Medicine, Dr. Rutherford is board certified in pediatrics and in
              general preventive medicine and public health, and has spent the
              majority of his professional career in public health practice,
              with a primary emphasis on the epidemiology and control of
              communicable diseases.
            </div>

            <div className={classes.bio}>Dr. Joel Samoff</div>
            <div className={classes.bioH2}>
              African Studies Adjunct Professor, Stanford University
            </div>
            <div>
              Prof. Joel Samoff is Adjunct Professor of Humanities and Sciences
              and the Center for African Studies at Stanford University. With a
              background in history, political science, and education, he
              researches and teaches about education and development. Concerned
              with public policy as well as research, and especially with the
              links between them, Prof. Samoff works regularly with UNESCO and
              international aid agencies involved in African education as a
              researcher and policy advisor. He has served as the principal
              advisor for the Joint Evaluation of External Support to Basic
              Education. He is an Advisory Editor for the International Journal
              of Educational Development and serves on the editorial boards of
              the Journal of Educational Research in Africa and the Southern
              Africa Review of Education.
            </div>

            <div className={classes.bio}>Dr. Lee Sanders</div>
            <div className={classes.bioH2}>
              Division Chief of General Pediatrics, Stanford University School
              of Medicine
            </div>
            <div>
              Lee M. Sanders, MD, MPH is Associate Professor of Pediatrics,
              Co-Director of the Center for Policy, Outcomes and Prevention
              (CPOP), and Division Chief for General Pediatrics at Stanford
              University. Dr. Sanders is a national expert in the fields of
              health literacy and health disparities. With funding from the NIH,
              CDC, and other national agencies and foundations — Dr. Sanders
              directs the Stanford Health Literacy Lab, which aims to address
              child disparities (including obesity, chronic illness, and
              educational achievement), by reimagining primary-care and
              community systems to support parents and families. Through this
              lab, Dr. Sanders also leads multi-disciplinary teams that provide
              analytic guidance to public policy at the local, state and
              national levels. He has served as an advisor to several national
              agencies, including the American Academy of Pediatrics, the
              American Cancer Society, the Institute of Medicine, the CDC, and
              the FDA. At Lucile Packard Children’s Hospital and the Gardner
              Packard Children’s Health Center, he provides primary care to
              children with medical and/or social complexity.
            </div>

            <div className={classes.bio}>Dr. Tina Seelig</div>
            <div className={classes.bioH2}>
              Executive Director, Stanford Technology Ventures Program
            </div>
            <div>
              Prof. Tina Seelig is the Executive Director for the Stanford
              Technology Ventures Program. She teaches courses on creativity,
              innovation, and entrepreneurship in the Management Science and
              Engineering department, and within the Hasso Plattner Institute of
              Design at Stanford. Prof. Seelig has won several awards, including
              the 2009 Gordon Prize from the National Academy of Engineering,
              recognizing her as a national leader in engineering education. Her
              latest books are Insight Out: Get Ideas Out of Your Head and Into
              the World, and Creativity Rules: Get Ideas Out of Your Head and
              into the World. She has a Ph.D. from Stanford University Medical
              School where she studied Neuroscience.
            </div>

            <div className={classes.bio}>Dr. Robert Siegel</div>
            <div className={classes.bioH2}>
              Professor of Microbiology and Immunology, Stanford University
            </div>
            <div>
              Prof. Robert Siegel is a Professor in the Department of
              Microbiology and Immunology, the Center of African Studies, and
              the Program in Human Biology at Stanford University. He is the
              Director of the Preclinical Infectious Disease Curriculum and was
              one of the original Co-directors of Global Health Scholarly
              Concentration at Stanford Medical School. His research explores
              medical education and curriculum development, particularly
              pertaining to infectious disease, virology, HIV, and molecular
              biology. He has received many awards for his dedication to
              research and teaching, including the Gores Award for Excellence in
              Teaching. He has a Ph.D. in Molecular Biology from the University
              of Colorado at Boulder, and an M.D. and Master’s in Education from
              Stanford University.
            </div>

            <div className={classes.bio}>Dr. Darvin Scott Smith</div>
            <div className={classes.bioH2}>
              Chief of Infectious Diseases and Geographic Medicine, Kaiser
              Permanente
            </div>
            <div>
              Department of Medical Microbiology and Immunology, Stanford
              Medical School
            </div>
            <div>
              Darvin (Scott) Smith graduated in biochemistry from Bowdoin
              College in Maine and went on to study tropical public health at
              Harvard School of Public health before attending medical school in
              his home state of Colorado, where he grew up. He worked on
              developing diagnostic tests and epidemiology (Leishmania and
              Onchocerciasis) in Cali Colombia on a Fulbright scholarship before
              finally moving to California where he completed residency at
              Stanford Medical School, then a Fellowship in Infectious Disease &
              Geographic Medicine. He now serves as Chief of the Dept of
              Infectious Disease at Kaiser Permanente in Redwood City
              California, and he teaches several classes at Stanford.
            </div>

            <div className={classes.bio}>Dr. Randall Stafford</div>
            <div className={classes.bioH2}>
              Professor of Medicine, Stanford University School of Medicine
            </div>
            <div>
              Dr. Randall Stafford is a Professor of Medicine and the Director
              of the Program on Prevention Outcomes and Practices at Stanford
              University. He is trained in both Internal Medicine and
              Epidemiology. Before coming to Stanford in 2001, he was on the
              faculty of Harvard University and Massachusetts General Hospital.
              His research focuses on: evaluating the quality and
              cost-effectiveness of how chronic disease medications are used;
              conducting randomized clinical trials testing the efficacy of
              online prevention tools and nurse case-management; and performing
              comparative effectiveness research, including the formulation of
              related health care policy.
            </div>

            <div className={classes.bio}>Dr. Paul Wise</div>
            <div className={classes.bioH2}>
              Richard E. Behrman Professor of Child Health and Society, Stanford
              University
            </div>
            <div>
              Dr. Paul Wise is the Richard E. Behrman Professor of Child Health
              and Society and Professor of Pediatrics and Health Policy at
              Stanford University School of Medicine. He also serves as Senior
              Fellow in the Center for Democracy, Development and the Rule of
              Law and the Center for International Security and Cooperation, at
              Stanford University’s Freeman-Spogli Institute for International
              Studies. He is also co-Director of the March of Dimes Center for
              Prematurity Research at Stanford University, and serves as a
              member of the Advisory Council of the National Institute of Child
              Health and Human Development. Dr. Wise received his A.B. degree in
              Latin American Studies, M.D. degree from Cornell University, a
              Master of Public Health degree from the Harvard School of Public
              Health and did his pediatric training at the Children’s Hospital
              in Boston.
            </div>

            <div className={classes.bio}>Dick Gould</div>
            <div className={classes.bioH2}>
              John L. Hinds Director of Tennis Emeritus, Stanford University
            </div>
            <div>
              Dick Gould is the John L. Hinds Director of Tennis Emeritus at
              Stanford University. As head coach of Stanford Men’s Tennis for 38
              years, he led the team to 17 NCAA Team Championships (top three
              among all NCAA coaches), 10 NCAA singles champions, and 7 NCAA
              doubles championship teams. Thirteen of his former players hold 72
              Grand Slam championships (singles, doubles, mixed), including
              tennis legends John McEnroe, Mike Bryan, and Bob Bryan. Gould’s
              leadership and service have been recognized widely over the years,
              including by the U.S. Olympic Committee and U.S. Professional
              Tennis Association (USPTA) among many others.
            </div>

            <div className={classes.bio}>Dr. Anurag Mairal</div>
            <div className={classes.bioH2}>
              Director of Global Outreach Programs, Stanford Biodesign
            </div>
            <div>
              Dr. Anurag Mairal is an Adjunct Professor of Medicine at Stanford
              and the Director, Global Outreach Programs at Stanford Biodesign.
              He is also lead faculty for Technology Innovation and Impact at
              Stanford Center for Global Health. He is the co-founder and
              Executive Vice President of Orbees Medical, a global healthcare
              strategy consulting firm. Prof. Mairal has an extensive background
              in medical technology development and commercialization,
              collaborating with partners in the U.S., India, China, and other
              emerging markets. An alumnus of global health nonprofit PATH, he
              serves as the Chair of its Bay Area Leadership Council. He is also
              a member of the Editorial Board of the IEEE Journal of
              Translational Engineering in Health and Medicine. His work has
              been published in more than 30 publications, and he has been
              issued seven patents
            </div>

            <div className={classes.bio}>Dr. Young Sung Lee</div>
            <div className={classes.bioH2}>
              Former President National Evidence-based Healthcare Collaborating
              Agency, Seoul, Republic of Korea
            </div>
            <div>
              Young Sung Lee, MD, Ph.D. is the former president of the National
              Evidence-based Healthcare Collaborating Agency(NECA), a
              governmental agency that conducts Health Technology
              Assessment(HTA) research based on scientific evidence. Dr. Lee is
              also a professor of Health Informatics and Management at the
              College of Medicine, Chungbuk National University. He is currently
              serving as a President and board member of the Korean Society of
              Medical Informatics. He has contributed to development of the
              medical informatics in Korea over the last two decades. He was a
              visiting scholar at Stanford University Medical Media and
              Information Technology and was a member of the Committee on
              Infrastructure Technologies, National Science and Technology
              Council, the Nation's highest decision-making body on science and
              technology policies under the President of the Republic of Korea.
            </div>

            <div className={classes.bio}>Betsy Scolnik</div>
            <div className={classes.bioH2}>
              Former President of Digital Media, National Geographic
            </div>
            <div>
              Betsy Scolnik is the founder of Scolnik Enterprises, where she has
              consulted on digital business and communications for major
              philanthropic and commercial media organizations including Stand
              Up To Cancer, The Streisand Foundation, The Paley Center for
              Media, TED, and Wolf Films/Law & OrderLaw, and more. Previously
              Betsy was the Director of Content and Distribution for the Bill
              and Melinda Gates Foundation, and the President of Digital Media
              for National Geographic. She held senior roles at AOL Time Warner
              and Xerox. Scolnik also sits on the board of Consumer Reports and
              Women’s March Global.
            </div>

            <div className={classes.bio}>Dr. Tsetan Dorji Sadutshang</div>
            <div>
              Chief Medical Officer, Delek Hospital in Dharamsala, Personal
              Physician to His Holiness the Dalai Lama
            </div>
            <div>
              Dr. Tsetan Dorji Sadutshang has served as Personal Physician to
              His Holiness the 14th Dalai Lama since 1988. In addition to these
              duties, Dr. Sadutshang serves as the Chief Medical Officer of the
              prestigious Delek Hospital in Dharamsala, India. After obtaining
              medical degrees at the universities of Chennai and Wales, Dr.
              Sadutshang received a Master of Public Health at Harvard
              University on a Fulbright Scholarship. Dr. Sadutshang is a
              founding board member of the Central Council of Traditional
              Medicine and a board member of the Tibet Charity. He has
              generously donated his voice and likeness to the role of the sage
              male doctor in the Tibetan version of the TeachAids software.
            </div>

            <div className={classes.bio}>Dr. Grant Miller</div>
            <div className={classes.bioH2}>
              Associate Professor, Stanford School of Medicine
            </div>
            <div>
              Grant Miller is Associate Professor of Medicine, Senior Fellow at
              the Freeman Spogli Institute for International Studies and Senior
              Fellow at the Stanford Institute for Economic Policy Research at
              Stanford University. His work has been published in top journals
              across disciplines, including economics, demography, science, and
              medicine. Professor Miller was also Director of the Stanford
              Center for International Development, and he led its growth into
              the Stanford King Center for Global Development, serving as its
              founding Director. He is a Research Associate at the National
              Bureau of Economic Research (NBER) and an affiliate of other
              leading research organizations focused on international
              development (including the Abdul Latif Jameel Poverty Action Lab
              and the International Growth Centre). Professor Miller received a
              BA from Yale University, a MPP from the John F. Kennedy School of
              Government at Harvard University, and a PhD in Health Policy from
              Harvard University.
            </div>

            <div className={classes.bio}>Dr. Raj Doshi</div>
            <div className={classes.bioH2}>
              Director of the India Biodesign Program and Adjunct Professor of
              Medicine, Stanford University
            </div>
            <div>
              Rajiv Doshi is an Adjunct Professor of Medicine at Stanford
              University and Director of the India Biodesign Program, a
              collaboration with the Government of India to train the next
              generation of Indian medical technology innovators. He serves as a
              pro-bono board member of TEAMFund, an impact investment fund
              focused on medical technology innovation for India and Africa. He
              is the Founder and CEO of Piper Biosciences which develops medical
              foods to support the management of chronic medical conditions. He
              has 70 patents and is the inventor of nasal EPAP to treat sleep
              apnea and snoring. A Soros Fellow, he earned degrees in chemical
              engineering, mechanical engineering and medicine from Stanford
              University.
            </div>

            <div className={classes.bio}>David Milestone</div>
            <div className={classes.bioH2}>
              Senior Advisor, McKinsey & Company
            </div>
            <div>
              David Milestone is a senior advisor with McKinsey & Company in its
              social sector practice based in Washington, DC. Most recently,
              Milestone was Director (acting) of USAID’s Center for Innovation
              and Impact (CII). Prior to joining USAID, he held various roles at
              Stryker, where he led innovation, introduction, and strategy
              initiatives in India. Milestone has also worked as a management
              consultant with AT Kearney and as a design engineer at Boeing. He
              holds an MBA from the Kellogg School of Management at Northwestern
              University, an MPA from Harvard’s Kennedy School of Government, an
              MS in Biomechanical Engineering from Stanford University, and a BS
              in Mechanical Engineering from the University of
              Wisconsin-Madison.
            </div>

            <div className={classes.bio}>Dr. Michele Barry</div>
            <div className={classes.bioH2}>
              Senior Associate Dean of Global Health, Stanford University School
              of Medicine
            </div>
            <div>
              Michele Barry, MD, FACP, FASTMH is the Drs. Ben and A. Jess
              Shenson Professor of Medicine and Tropical Diseases, Director of
              the Center for Innovation in Global Health, and Senior Associate
              Dean for Global Health at Stanford University. She is the
              Co-Founder and Director of the Yale/Stanford Johnson & Johnson
              Global Health Scholar Award program and a past President of the
              American Society of Tropical Medicine and Hygiene. Dean Barry is
              an elected member of the National Academy of Science Medicine and
              has been selected for Best Doctors in America. She is the 2018
              recipient of AMWA’s highest award – the Elizabeth Blackwell medal
              for creating pathways for women in medicine.
            </div>
          </Typography>
          <Typography variant="h5" className={classes.headerGeneral}>
            &nbsp;
          </Typography>
        </Paper>
      </div>
    </box>
  );
}
